import { RouteDefinition } from '@polestar/web3-core-react';

/**
 * Array of route definition object
 * Each object will be map to react-router-dom <Route /> component and respect the following shape
 *
 * - name (string) : name of the route, internal reference used to generate a path for a given route
 * - path (string) : uri fragment, can contain variables in react-router-dom's fashion (e.g. /:locale/something/:id)
 * - exact (boolean) : wether to stricly match path or allow sub-routes (@see react-router-dom)
 * - view (string) : name of the view component to render
 * - private (boolean) : wether the route should be accessible publicly or not
 * - sitemap (object) : public sitemap.xml informations (if set to undefined, will be ignored by the sitemap and not indexed)
 */
const routes = <RouteDefinition[]>[
  {
    name: 'login-view',
    path: `/login`,
    exact: true,
    view: 'LoginView',
    private: false,
    sitemap: undefined
  },
  {
    name: 'submissions',
    path: '/submissions',
    exact: true,
    view: 'SubmissionListView',
    private: true,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    }
  },
  {
    name: 'submission-details',
    path: `/submissions/:id`,
    exact: true,
    view: 'DetailsView',
    private: true,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 2
    }
  }
];

if (process.env.APP_ENV !== 'prod') {
  // Add home page for testing on dev and staging
  routes.unshift({
    name: 'home',
    path: `/`,
    exact: true,
    view: 'HomeView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    }
  });
}

export default routes;

import {
  useQuery,
  DocumentNode,
  TypedDocumentNode,
  OperationVariables,
  QueryHookOptions,
  ApolloQueryResult
} from '@apollo/client';
import { DownloadObj } from '~typings';

interface DownloadUrlList {
  downloadUrlList: DownloadObj[];
}

interface VariableProps {
  submissionId?: string;
  fileName?: string;
}

export const useAsyncLazyQuery = (
  // eslint-disable-next-line
  query: DocumentNode | TypedDocumentNode<any, OperationVariables>,
  options: QueryHookOptions
): ((variables: VariableProps) => Promise<ApolloQueryResult<DownloadUrlList>>) => {
  const { refetch } = useQuery(query, { ...options, skip: true });

  const imperativelyCallQuery = (variables: VariableProps) => {
    return refetch(variables);
  };

  return imperativelyCallQuery;
};

import React from 'react';

import MSALProvider from './MSALContext';

/**
 * Implement all the different context provider here, they will be passed to the application
 */
const AppContextProvider = (props: Record<string, unknown>): JSX.Element => {
  return <MSALProvider {...props} />;
};

export default AppContextProvider;

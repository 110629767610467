import { PublicClientApplication } from '@azure/msal-browser';
import { useContext } from 'react';
import { Claims } from '~typings';
import { MSALContext } from '~src/app/contexts/MSALContext';

interface MSAL {
  msalApp: PublicClientApplication | undefined;
  login: () => void;
  logout: () => void;
  getIdToken: () => string | null;
  getClaims: () => Claims | null;
  loading: boolean;
  isAuthenticated: boolean;
}

export const useMSAL = (): MSAL => {
  return useContext(MSALContext);
};

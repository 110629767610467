import React from 'react';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';
import { Header, PolestarApp, PageContent, Footer } from '@polestar/component-warehouse-react';
import {
  ErrorBoundary,
  RouteDefinition,
  Themes,
  generateRouteUrl
} from '@polestar/web3-core-react';

import routes from '~routes/definition';
import { ProtectedRoute } from '~components';
import { Drawer } from './components/Drawer';

const LoadableView = loadable((props: { view: string }) => import(`./views/${props.view}/index`));

const LoadableRouter = (): JSX.Element => {
  const getView = (routeDefinition: RouteDefinition | null, routeProps: RouteComponentProps) => (
    <LoadableView {...routeProps} view={routeDefinition?.view || 'NotFoundView'} />
  );
  const getNotFoundView = (
    _routeDefinition: RouteDefinition | null,
    routeProps: RouteComponentProps
  ) => <LoadableView {...routeProps} view="NotFoundView" />;

  return (
    <Switch>
      {routes.map((route: RouteDefinition) => {
        const RouteComponent = route.private ? ProtectedRoute : Route;
        return (
          <RouteComponent
            key={`view.${route.name}`}
            exact={route.exact}
            path={route.path}
            render={(props: RouteComponentProps) => getView(route, props)}
          />
        );
      })}
      <Route
        exact
        path="/"
        render={() => <Redirect to={generateRouteUrl(routes, 'submissions')} />}
      />
      <Route render={(props) => getNotFoundView(null, props)} />
    </Switch>
  );
};

const App = (): JSX.Element => {
  return (
    <ErrorBoundary>
      <PolestarApp rootSelector="#root" theme={Themes.default}>
        <Header
          logoProps={{
            as: 'a',
            href: '/',
            target: '_self',
            rel: 'noopener'
          }}
        />

        <Drawer />

        <PageContent>
          <LoadableRouter />
        </PageContent>

        <Footer />
      </PolestarApp>
    </ErrorBoundary>
  );
};

export default App;

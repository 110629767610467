export interface Config {
  application: {
    baseUrl: string | undefined;
    domain: string | undefined;
  };
  api: {
    global: string | undefined;
    china: string | undefined;
    region: string;
  };
  dato: {
    assetUrl: string | undefined;
    baseUrl: string | undefined;
    previewUrl: string | undefined;
    token: string | undefined;
  };
  sentry: {
    dsn: string | undefined;
    environment: string | undefined;
    release?: string | undefined;
    tracesSampleRate?: number | undefined;
  };
  auth: {
    clientId: string;
    tenantId: string;
    authorityUrl: string | undefined;
  };
}
// Application specific configuration (environment based)
// We expose a function instead of an object to make sure dotenv has been called before the config itself is defined
// Otherwise all process.env.XXX are undefined.
const getRuntimeConfig = (): Config => ({
  application: {
    // Define parameters specific to the application itself (environment-based or not)
    baseUrl: process.env.APP_BASE_URL,
    // Domain for authentication cookie
    domain: process.env.APP_DOMAIN
  },
  api: {
    // Define parameters specific to the API (environment-based or not)
    global: process.env.API_GLOBAL_BASE_URL,
    china: process.env.API_CHINA_BASE_URL,
    region: <'global' | 'china'>process.env.API_REGION || 'global'
  },
  dato: {
    // Define parameters specific to the content API (environment-based or not)
    assetUrl: process.env.DATO_ASSET_BASE_URL,
    baseUrl: process.env.DATO_BASE_URL,
    previewUrl: process.env.DATO_PREVIEW_BASE_URL,
    token: process.env.DATO_TOKEN
  },
  sentry: {
    // Various Sentry parameters
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_STAGE,
    release: process.env.SENTRY_RELEASE,
    tracesSampleRate: Number(process.env.SENTRY_SAMPLE_RATE) || 0
  },
  auth: {
    clientId: process.env.MSAL_AUTH_CLIENT_ID || '',
    tenantId: process.env.MSAL_AUTH_TENANT_ID || '',
    authorityUrl: process.env.MSAL_AUTHORITY_URL
  }
});

export default getRuntimeConfig;

import React from 'react';
import {
  Spacer,
  Spacings,
  Title,
  Column,
  SecondaryButton,
  Icons,
  Section,
  Alignments
} from '@polestar/component-warehouse-react';
import { ViewProps } from '@polestar/web3-core-react';
import { useMSAL } from '~hooks';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UnauthorizedViewProps extends ViewProps {}

const UnauthorizedView: React.FC<UnauthorizedViewProps> = ({
  staticContext
}: UnauthorizedViewProps) => {
  const { login } = useMSAL();

  if (staticContext) {
    // Forward statusCode to SSR response
    staticContext.statusCode = 401;
  }

  return (
    <Column stretch>
      <Section>
        <Spacer spacing={Spacings.xxLarge} />
        <Title>{'You must be logged in to access this page'}</Title>
        <Spacer spacing={Spacings.small} />
        <SecondaryButton
          icon={Icons.arrowRight}
          iconPosition={Alignments.right}
          // trailing={<Icon icon={Icons.arrowRight} size={Sizes.small} color="red" />}
          onClick={() => login()}
        >
          {'Sign in'}
        </SecondaryButton>
        <Spacer spacing={Spacings.xxLarge} />
      </Section>
    </Column>
  );
};

UnauthorizedView.defaultProps = {
  staticContext: {}
};

export default UnauthorizedView;

import { AccountInfo } from '@azure/msal-browser';
import { useEffect, useRef } from 'react';

export const useInterval = (
  callback: (activeAccount: AccountInfo | undefined) => void,
  delay: number
): void => {
  // eslint-disable-next-line
  const savedCallback = useRef<any>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback && savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

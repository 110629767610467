import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, split } from '@apollo/client';
import { getErrorLink } from '@polestar/graphql-client';
import getRuntimeConfig from '~config';

const config = getRuntimeConfig();

// check if we are to create the dato apollo client for the preview site or the regular site
let datoPreviewToken = '';
try {
  datoPreviewToken = new URLSearchParams(window.location.search).get('preview') || '';
} catch (error) {} /* eslint-disable-line no-empty */

const clientConfig = {
  datoUrl: datoPreviewToken ? <string>config.dato.previewUrl : <string>config.dato.baseUrl,
  datoToken: datoPreviewToken || <string>config.dato.token
};

const client = new ApolloClient({
  link: ApolloLink.from([
    new ApolloLink((operation, forward) => {
      // add the authorization to the headers
      operation.setContext(({ headers = {} }) => {
        const isDatoContext = operation.getContext().dato === true;
        const azureToken = sessionStorage.getItem(`contest_admin.id_token`);

        const token = isDatoContext ? clientConfig.datoToken : azureToken;

        const authHeaders = {
          ...(token ? { authorization: `Bearer ${token}` } : {})
        };

        return {
          headers: {
            ...headers,
            ...authHeaders
          },
          credentials: 'include'
        };
      });

      if (typeof window !== 'undefined' && operation.getContext().dato === true) {
        operation.variables.origin = window.location.origin;
      }

      return forward(operation);
    }),

    getErrorLink(),
    split(
      (operation) => operation.getContext().dato === true,
      new HttpLink({
        fetch: (uri: string, options: RequestInit | undefined) =>
          fetch(uri.replace(/__DATO_BASE_URL__/gi, clientConfig.datoUrl), options),
        // make sure the path gets replaced at runtime for each request:
        // ApolloClient is only initialised only at page load (first render)
        uri: '__DATO_BASE_URL__',
        useGETForQueries: true // always use get for Dato queries to utilize cache
      }),
      split(
        (operation) => operation.getContext().china === true,
        new HttpLink({
          fetch: (uri: string, options: RequestInit | undefined) => {
            return fetch(uri.replace(/__API_BASE_URL__/gi, config.api['china'] ?? ''), options);
          },
          // make sure the path gets replaced at runtime for each request:
          // ApolloClient is only initialised only at page load (first render)
          uri: '__API_BASE_URL__',
          useGETForQueries: true,
          credentials: 'include'
        }),
        new HttpLink({
          fetch: (uri: string, options: RequestInit | undefined) => {
            return fetch(uri.replace(/__API_BASE_URL__/gi, config.api['global'] ?? ''), options);
          },
          // make sure the path gets replaced at runtime for each request:
          // ApolloClient is only initialised only at page load (first render)
          uri: '__API_BASE_URL__',
          useGETForQueries: true,
          credentials: 'include'
        })
      )
    )
  ]),
  cache: new InMemoryCache({
    addTypename: false
  })
});

export default client;

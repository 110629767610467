import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import UnauthorizedView from '~src/app/views/UnauthorizedView';
import { useMSAL } from '../hooks';

export interface ProtectedRouteProps extends RouteProps {
  redirectTo?: string;
}

export const ProtectedRoute = ({ redirectTo, ...props }: ProtectedRouteProps): JSX.Element => {
  const { isAuthenticated } = useMSAL();

  if (isAuthenticated) return <Route {...props} />;

  return redirectTo ? <Redirect to={redirectTo} /> : <UnauthorizedView />;
};

import React from 'react';
import { useQuery } from '@apollo/client';
import GET_DRAWER from '~app/apollo/queries/GetDrawer.gql';
import { NavigationDrawer } from '@polestar/component-warehouse-react';

interface LinkProps {
  label: string;
  position: number;
  href: string;
}

export const Drawer: React.FC = () => {
  const { data, loading, error } = useQuery<{
    links: LinkProps[];
    quickLinks: LinkProps[];
  }>(GET_DRAWER, {
    context: {
      dato: true
    }
  });

  if (!data || loading || error) return null;

  const links = data.links
    ? [...data.links].sort((currentLink, nextLink) => currentLink.position - nextLink.position)
    : [];

  const quickLinks = data.quickLinks
    ? [...data.quickLinks].sort((currentLink, nextLink) => currentLink.position - nextLink.position)
    : [];

  //@ts-ignore
  return <NavigationDrawer links={links} quickLinks={quickLinks} />;
};

import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AccountInfo, AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import getRuntimeConfig from '~config';
import { generateRouteUrl } from '@polestar/web3-core-react';
import routes from '~routes/definition';
import { useInterval } from '~hooks';
import { Claims } from '~typings';

const config = getRuntimeConfig();

const CLAIMS = 'contest_admin.id_token.claims';
const ID_TOKEN = 'contest_admin.id_token';

let loginInProgress = false;

export const MSALContext = React.createContext({
  msalApp: {} as PublicClientApplication | undefined,
  login: () => {},
  logout: () => {},
  getIdToken: (): string | null => {
    return '';
  },
  getClaims: (): Claims | null => {
    return {} as Claims;
  },
  loading: true,
  isAuthenticated: false
});

const MSALProvider = ({ ...props }: Record<string, unknown>): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [account, setAccount] = useState<AccountInfo | undefined>();

  const msalApp: MutableRefObject<PublicClientApplication | undefined> = useRef();

  const baseRedirectUri = `${location.origin}${generateRouteUrl(routes, 'submissions')}`;

  const authConfig = useMemo(
    () => ({
      clientId: config.auth.clientId,
      authority: config.auth.authorityUrl + config.auth.tenantId,
      redirectUri: baseRedirectUri,
      postLogoutRedirectUri: baseRedirectUri,
      navigateToLoginRequestUrl: true
    }),
    [baseRedirectUri]
  );

  const setIdTokenClaims = (tokenResponse: AuthenticationResult) => {
    sessionStorage.setItem(ID_TOKEN, tokenResponse.idToken);
    sessionStorage.setItem(CLAIMS, JSON.stringify(tokenResponse.idTokenClaims));
  };

  const clearIdTokenClaims = () => {
    sessionStorage.removeItem(ID_TOKEN);
    sessionStorage.removeItem(CLAIMS);
  };

  const setLocation = (tokenResponse: AuthenticationResult) => {
    const state = tokenResponse.state ? JSON.parse(tokenResponse.state) : {};
    const uri = state && state.uri ? state.uri : '';

    if (uri !== '') {
      window.location.replace(uri);
    } else {
      window.location.reload();
    }
  };

  const login = () => {
    if (loginInProgress) {
      return;
    }
    loginInProgress = true;
    clearIdTokenClaims();

    msalApp?.current?.loginRedirect({
      ...authConfig,
      scopes: ['openid', 'profile', 'email']
    });
  };

  const logout = useCallback(() => {
    msalApp?.current?.logoutRedirect().then(clearIdTokenClaims);
  }, []);

  const getIdToken = (): string | null => sessionStorage.getItem(ID_TOKEN);
  const getClaims = (): Claims | null => {
    const claims = sessionStorage.getItem(CLAIMS);
    return claims ? JSON.parse(claims) : null;
  };

  // Refresh token if near expiration.
  const checkToken = (activeAccount: AccountInfo | undefined) => {
    if (msalApp.current) {
      const request = {
        scopes: ['openid', 'profile', 'email'],
        account: activeAccount,
        forceRefresh: false
      };

      //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md
      msalApp.current
        .acquireTokenSilent(request)
        .then((res) => {
          setIdTokenClaims(res);
        })
        .catch((error) => {
          console.log(error);
          setIsAuthenticated(false);
          msalApp.current?.acquireTokenRedirect(request);
        });
    }
  };

  // Check token every 10 min.
  useInterval(() => {
    checkToken(account);
  }, 10 * 60 * 1000);

  useEffect(() => {
    if (loading) return;

    const accounts = msalApp.current?.getAllAccounts();

    // If account is found, user is logged in.
    if (accounts && accounts.length > 0) {
      setAccount(accounts[0]);
      setIsAuthenticated(true);
      checkToken(accounts[0]); // Check token on page load
    }
    //eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    //@ts-ignore
    if (!(window.crypto || window.msCrypto)) {
      // eslint-disable-next-line no-console
      console.error('Browser does not support window.crypto');
      return;
    }

    msalApp.current = new PublicClientApplication({
      auth: authConfig,
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false
      }
    });

    msalApp.current
      .handleRedirectPromise()
      .then((tokenResponse: AuthenticationResult | null) => {
        if (tokenResponse !== null) {
          setIdTokenClaims(tokenResponse);
          setLocation(tokenResponse);
          setIsAuthenticated(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.message.startsWith('interaction_in_progress')) {
          // eslint-disable-next-line no-console
          console.info(error);
        } else {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      });
  }, [msalApp, authConfig]);

  return (
    <MSALContext.Provider
      {...props}
      value={{
        msalApp: msalApp.current,
        login,
        logout,
        getIdToken,
        getClaims,
        loading,
        isAuthenticated
      }}
    ></MSALContext.Provider>
  );
};

export default MSALProvider;
